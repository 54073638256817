<template>
  <div class="create-block-buttons block-element">
    <b-button variant="success" @click="openModal"> Создать </b-button>
    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Создание скрипта</h5>
      </template>

      <notificaions-scripts-form
        v-model="createItem"
        ref="notificationsScriptsCreateForm"
      />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="saveForm">
          Создать
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NotificaionsScriptsForm from '@/view/content/forms/notifications/scripts/ScriptsForm';

export default {
  components: {
    NotificaionsScriptsForm,
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        Code: '',
        FillSP: '',
        TypeID: 1,
        Name: '',
        Notes: '',
        IsActive: true,
        ActionPath: null,
      },
    };
  },

  methods: {
    openModal() {
      this.clearForm();
      this.modalShow = true;
    },

    async saveForm() {
      const result = await this.$refs?.notificationsScriptsCreateForm?.submit();

      if (result) {
        this.modalShow = false;
        this.$emit('saved');
      }
    },

    clearForm() {
      this.createItem = {
        Code: '',
        FillSP: '',
        TypeID: 1,
        Name: '',
        Notes: '',
        IsActive: true,
        ActionPath: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
