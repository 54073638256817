<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div v-show="isExists" class="form-subheader">Сведения</div>
          <b-form-group
            id="input-group-0"
            v-show="isExists"
            label="OID"
            label-for="input-0"
          >
            <b-form-input
              id="input-0"
              :value="form.OID"
              placeholder="OID"
              disabled
            ></b-form-input>
          </b-form-group>

          <!--<b-form-group id="input-group-2" label="Имя" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.name"
              placeholder="Имя"
              disabled
            ></b-form-input>
          </b-form-group>-->

          <div class="form-subheader">Редактируемые</div>
          <b-form>
            <b-form-group id="input-group-1" label="Код" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="form.Code"
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Название"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.Name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Примечание"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="form.Notes"
                placeholder="Примечание"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4">
              <switch-checkbox v-model="form.IsActive" label="Активна" />
            </b-form-group>

            <b-form-group id="input-group-5" label="Тип" label-for="input-5">
              <b-form-select
                v-model.trim="form.TypeID"
                :options="typeOptions"
                id="input-type"
              />
            </b-form-group>

            <b-form-group id="input-group-6" label="Экшен" label-for="input-6">
              <b-form-select
                v-model.trim="form.ActionPath"
                :options="actionPathOptions"
                id="input-type"
              />
            </b-form-group>

            <b-form-group
              id="input-group-7"
              label="Процедура"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="form.FillSP"
                placeholder="Процедура"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { SAVE_ITEM_NOTIFICATION_SCRIPTS } from '@/core/services/store/notifications.module';

export default {
  components: { SwitchCheckbox },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
    };
  },

  computed: {
    isExists() {
      return this.form?.OID;
    },

    typeOptions() {
      return this.$store.getters.reminderTypesOptions;
    },

    actionPathOptions() {
      return this.$store.getters.actionPathsOptions.map((x) => x.text);
    },
  },

  validations: {
    form: {
      Code: {
        required,
      },
      Name: {
        required,
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      } else {
        const result = await this.$store.dispatch(
          SAVE_ITEM_NOTIFICATION_SCRIPTS,
          this.form
        );

        if (result.error === null) {
          Swal.fire({
            title: '',
            text: this.isExists ? 'Сохранено!' : 'Создано!',
            icon: 'success',
            heightAuto: false,
          });
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
