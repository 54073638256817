export default {
  data() {
    return {
      filtered: false,
    };
  },

  computed: {
    filterItems() {
      const keys = Object.keys(this.$route.query);
      if (keys.length > 0) {
        this.filtered = true;
        const filteredArray = this.items
          .map((item) => {
            const newItem = {};
            keys.forEach((key) => {
              if (
                item?.[key]
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(this.$route.query[key].toString().toLowerCase())
              ) {
                if (item.OID) {
                  newItem.OID = item.OID;
                }
                newItem[key] = item[key];
              } else {
                newItem[key] = null;
              }
            });
            return newItem;
          })
          .filter((x) => Object.keys(x).length > 0)
          .filter((x) => {
            const exluded = [];

            Object.keys(x).forEach((key) => {
              exluded.push(x[key] === null);
            });
            return exluded.every((x) => x === false);
          });

        return this.items.filter((x) => {
          const oids = filteredArray.map((z) => z.OID);
          return oids.includes(x.OID);
        });
      }
      this.filtered = false;
      return this.items;
    },
  },
};
