<template>
  <div class="auto-dealer-table">
    <v-data-table
      v-if="items !== null"
      :headers="headers"
      :items="localItems"
      item-key="OID"
      :item-class="itemClass"
      class="elevation-1 row-links"
      hide-default-footer
      disable-pagination
    >
      <template #item.IsActive="{ item }">
        <yes-no-badge :active="item.IsActive" />
      </template>

      <template #item.OID="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.OID } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.OID)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal
      v-model="modalShow"
      scrollable
      size="xl"
      @ok="saveForm"
      @hide="editItem = null"
    >
      <template #modal-title>
        <h5>Редактирование {{ editedName }}</h5>
      </template>

      <scripts-form
        v-model="editItem"
        ref="notificationsScriptsForm"
        @saved="saved"
      />

      <template #modal-footer="{ cancel }">
        <b-button
          :disabled="buttonLoading"
          size="sm"
          variant="success"
          @click="saveForm"
        >
          Сохранить
        </b-button>
        <b-button
          :disabled="buttonLoading"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import YesNoBadge from '@/view/content/YesNoBadge';
import ScriptsForm from '@/view/content/forms/notifications/scripts/ScriptsForm';
import { GET_ITEM_NOTIFICATION_SCRIPTS } from '@/core/services/store/notifications.module';

export default {
  components: { YesNoBadge, ScriptsForm },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    filtered: {
      type: Boolean,
      deafult: () => false,
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      localItems: this.items,
      lastEditId: null,
      buttonLoading: false,
      headers: [
        { text: 'Код', value: 'Code', sortable: false },
        { text: 'Название', value: 'Name', sortable: false },
        {
          text: 'Примечание',
          value: 'Notes',
          sortable: false,
        },
        {
          text: ' Активен',
          value: 'IsActive',
          sortable: false,
        },
        { text: 'Тип', value: 'TypeName', sortable: false },
        { text: 'Действия', sortable: false, value: 'OID' },
      ],
    };
  },

  computed: {
    editedName() {
      return this.editItem?.Name;
    },
  },

  mounted() {
    if (this.localItems === null) {
      this.localItems = [];
    }
  },

  watch: {
    items(v) {
      if (v === null) {
        this.localItems = [];
      } else {
        this.localItems = v;
      }
    },
  },

  methods: {
    saved() {
      this.$emit('updated');
    },

    async clickRow(oid) {
      this.lastEditId = oid;
      this.editItem = await this.$store.dispatch(
        GET_ITEM_NOTIFICATION_SCRIPTS,
        oid
      );
      this.modalShow = true;
      return false;
    },

    async saveForm() {
      this.buttonLoading = true;
      const result = await this.$refs?.notificationsScriptsForm?.submit();
      this.buttonLoading = false;

      if (result) {
        this.modalShow = false;
        this.$emit('updated');
      }
    },

    itemClass() {
      return this.filtered ? 'filtered' : '';
    },
  },
};
</script>

<style lang="scss"></style>
