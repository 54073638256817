<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-name"
              label="Название"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.Name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group
              id="input-group-notes"
              label="Примечание"
              label-for="input-notes"
            >
              <b-form-input
                id="input-notes"
                v-model.trim="f.Notes"
                placeholder="Примечание"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group
              id="input-group-type"
              label="Тип"
              label-for="input-type"
            >
              <b-form-select
                v-model.trim="f.TypeName"
                :options="typeOptions"
                id="input-type"
              />
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-route"
              label="Активен"
              label-for="input-route"
            >
              <switch-checkbox v-model="f.IsActive" label="Активен" />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@/view/content/filters/FiltersMixin';
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        Name: this.$route.query?.Name,
        Notes: this.$route.query?.Notes,
        TypeName: this.$route.query?.TypeName,
        IsActive: this.$route.query?.IsActive,
      },
    };
  },

  computed: {
    typeOptions() {
      const result = this.$store.getters.reminderTypesOptions.map(
        (x) => x.text
      );
      result.unshift('');
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
